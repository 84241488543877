<template>
  <header class="md-shared-header1" :class="[$store.state.fixed ? 'md-shared-header-fixed1' : '']">
    <div class="md-nav-box">
      <ul class="class-list">
        <!-- <li class="class-item" @click="$router.go(-1)">
          <em><i class="el-icon-back"></i>返回</em>
          <span>{{title}}</span>
        </li> -->
      </ul>
      <div class="nav-left">
        <div class="search-box" style="margin-left: 50px">
          <el-input
            placeholder="请输入关键字..."
            class="search-input"
            v-model="searchVal">
            <text slot="suffix" class="el-icon-search" @click="handleSearch"> 搜索</text>
          </el-input>
        </div>
        <MdNavClassComponent />
      </div>
    </div>
  </header>
</template>

<script>
import MdNavClassComponent from '../mdNav/index.vue'
export default {
  name: 'MdSharedHeaderComponent',
  components: {
    MdNavClassComponent
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLogin: false,
      user: null,
      searchVal: ''
    }
  },
  mounted () {
    this.user = localStorage.getItem('MD_USER')
    this.handleListenerScroll()
  },
  methods: {
    handleSearch () {
      if (!this.searchVal) {
        this.$message.error('请输入关键字')
        return
      }
      this.$router.push({
        path: '/search',
        query: {
          keyword: this.searchVal
        }
      })
    },
    handleNav (item) {
      this.$router.push({
        path: item.path
      })
    },
    handleGoHome () {
      window.open('/')
    },
    handleListenerScroll () {
      window.addEventListener('scroll', () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        this.$store.state.fixed = scrollTop > 80
        if (['/login'].includes(this.$route.path)) {
          this.$store.state.fixed = true
        }
      })
    },
    handleCreateActivity () {
      if (!this.user) {
        this.$router.push('/login')
      } else {
        this.$router.push('/manage/home')
      }
    },
    handleMouseover (index) {
      this.subMenusIndex = index
    },
    handleSubMenuClick (item) {
      this.$router.push(item.url)
    },
    handleLogout () {
      this.isLogin = false
    }
  }
}
</script>

<style lang="less" scoped>
.md-shared-header1 {
  width: 100%;
  height: @md-header-height;
  box-sizing: border-box;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 998;
  background: #fff;
  &.md-shared-header-fixed1 {
    background-color: #fff !important;
    box-shadow: 0 6px 10px 0 rgb(0 0 0 / 2%) !important;
  }
  .md-nav-box {
    width: 100%;
    height: @md-header-height;
    padding: 0 20px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .class-list {
      display: flex;
      align-items: center;
      .class-item {
        display: flex;
        align-items: center;
        em {
          display: flex;
          align-items: center;
          cursor: pointer;
          &:hover {
            color: @md-primary-color;
          }
        }
        i {
          padding: 10px;
          font-size: 20px;
          cursor: pointer;
        }
        span {
          display: block;
          font-size: 18px;
          height: 63px;
          line-height: 63px;
          color: #1C1F21;
          position: relative;
          padding: 0 16px;
          font-weight: bold;
          margin-left: 10px;
        }
        .active {
          color: #1C1F21;
          font-weight: 500;
        }
      }
    }
    .nav-left {
      height: 100%;
      font-size: 16px;
      color: #545C63;
      display: flex;
      align-items: center;
      i {
        font-weight: bold;
        cursor: pointer;
        &:hover {
          color: @md-primary-color;
        }
      }
    }
  }
}
.search-box {
  /deep/ .search-input {
    .el-input__inner {
      border: none;
      width: 240px;
      box-sizing: border-box;
      background: #F3F5F6;
      border-radius: 18px;
      border: 1px solid rgba(15, 176, 155, 1);
      height: 36px;
    }
    .el-input__suffix {
      i {
        margin-top: 10px;
        margin-right: 3px;
        font-size: 20px;
        font-weight: bold;
        color: #545C63;
        cursor: pointer;
        &:hover {
          color: @md-primary-color;
        }
      }
    }
  }
}
</style>
